
















import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
@Component
export default class MinCard extends Vue {
  @PropSync('config') configSync!: any;
  @Prop() tip!: String;

  clickedType(type: number) {
    this.$emit('link', type);
  }
}

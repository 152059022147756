import { mock, Random } from 'mockjs';

const gernerate = (i: number) => {
  let month = Random.date('MM');
  return mock({
    c1: `TXX20211130005${i}`,
    c2: `XXXX${month}发卡支出结算单模板`,
    c3: '待经办',
    c4: `2021${month}`,
    c5: '10,000,000.00',
    c6: '市场费用',
    c7: '市场营销部市场营销部',
    c8: '麦当劳（中国）有限公司',
    c9: '麦当劳-精彩365-2021年合同',
    c10: '麦当劳-精彩365-2021年合作项目',
    c11: Random.cname(),
    c12: '2021-11-30 ' + Random.time(),
    c13: Random.cname(),
    c14: '2021-11-30 ' + Random.time()
  });
};

let d = [];
for (let i = 100; i < 111; i++) {
  d.push(gernerate(i));
}

export const list = d;

export const columns = [
  { title: '模板编码', dataIndex: 'c1', width: 200 },
  { title: '模板名称', dataIndex: 'c2', width: 200 },
  { title: '模板状态', dataIndex: 'c3', width: 120 },
  { title: '业务类型', dataIndex: 'c6', width: 100 },
  { title: '所属部门及分中心', dataIndex: 'c7', width: 200 },
  { title: '经办人', dataIndex: 'c11', width: 100 },
  { title: '经办时间', dataIndex: 'c12', width: 200 },
  { title: '最近操作人', dataIndex: 'c13', width: 120 },
  { title: '最近操作时间', dataIndex: 'c14', width: 200 },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    width: 180,
    scopedSlots: {
      customRender: 'action'
    }
  }
];
